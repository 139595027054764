import { collection, query, where, getDocs, addDoc, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from './firebase';

export interface ActivityList {
  id: string;
  userId: string;
  name: string;
  activities: string[];
  createdAt: string;
}

export const createList = async (userId: string, name: string): Promise<ActivityList> => {
  try {
    const list = {
      userId,
      name,
      activities: [],
      createdAt: new Date().toISOString()
    };

    const docRef = await addDoc(collection(db, 'activityLists'), list);
    return { id: docRef.id, ...list };
  } catch (error: any) {
    throw new Error(error.message || 'Failed to create list');
  }
};

export const getUserLists = async (userId: string): Promise<ActivityList[]> => {
  try {
    const q = query(collection(db, 'activityLists'), where('userId', '==', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ActivityList));
  } catch (error: any) {
    throw new Error(error.message || 'Failed to fetch lists');
  }
};

export const addActivityToList = async (listId: string, activityId: string): Promise<void> => {
  try {
    const listRef = doc(db, 'activityLists', listId);
    await updateDoc(listRef, {
      activities: arrayUnion(activityId),
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to add activity to list');
  }
};

export const removeActivityFromList = async (listId: string, activityId: string): Promise<void> => {
  try {
    const listRef = doc(db, 'activityLists', listId);
    await updateDoc(listRef, {
      activities: arrayRemove(activityId),
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to remove activity from list');
  }
};