import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { initializeFirebase } from './config/firebase';

const initApp = async () => {
  // Initialize Firebase first
  await initializeFirebase().catch(console.error);

  // Then render the app
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

// Start the app
initApp();