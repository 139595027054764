import { collection, query, where, getDocs, updateDoc, doc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { User } from '../types';

export const searchUsers = async (searchTerm: string): Promise<User[]> => {
  const usersRef = collection(db, 'users');
  const snapshot = await getDocs(usersRef);
  
  return snapshot.docs
    .map(doc => ({ id: doc.id, ...doc.data() } as User))
    .filter(user => 
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
};

export const addFriend = async (userId: string, friendId: string) => {
  const userRef = doc(db, 'users', userId);
  const friendRef = doc(db, 'users', friendId);
  
  await updateDoc(userRef, {
    friends: arrayUnion(friendId)
  });
  
  await updateDoc(friendRef, {
    friends: arrayUnion(userId)
  });
};

export const removeFriend = async (userId: string, friendId: string) => {
  const userRef = doc(db, 'users', userId);
  const friendRef = doc(db, 'users', friendId);
  
  await updateDoc(userRef, {
    friends: arrayRemove(friendId)
  });
  
  await updateDoc(friendRef, {
    friends: arrayRemove(userId)
  });
};

export const getFriends = async (userId: string): Promise<User[]> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return [];
    }

    const userData = userDoc.data() as User;
    if (!userData.friends || !Array.isArray(userData.friends)) {
      return [];
    }

    const friendPromises = userData.friends.map(async (friendId) => {
      const friendDoc = await getDoc(doc(db, 'users', friendId));
      if (friendDoc.exists()) {
        return { id: friendDoc.id, ...friendDoc.data() } as User;
      }
      return null;
    });

    const friends = await Promise.all(friendPromises);
    return friends.filter((friend): friend is User => friend !== null);
  } catch (error) {
    console.error('Error getting friends:', error);
    return [];
  }
};