import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from './firebase';
import { User } from '../types';

export const getProfile = async (userId: string): Promise<User | null> => {
  if (!userId) return null;
  
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() } as User;
    }
    return null;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const updateProfile = async (
  userId: string, 
  updates: Partial<User>, 
  avatar?: File | null
): Promise<User> => {
  if (!userId) throw new Error('User ID is required');

  try {
    let avatarUrl = updates.avatar;
    
    if (avatar) {
      // Create a unique filename for the avatar
      const filename = `${userId}_${Date.now()}_${avatar.name}`;
      const storageRef = ref(storage, `avatars/${filename}`);
      
      // Upload the file
      const snapshot = await uploadBytes(storageRef, avatar);
      
      // Get the download URL
      avatarUrl = await getDownloadURL(snapshot.ref);
    }

    const userRef = doc(db, 'users', userId);
    const updatedData = {
      ...updates,
      ...(avatarUrl && { avatar: avatarUrl }),
      updatedAt: new Date().toISOString()
    };

    await setDoc(userRef, updatedData, { merge: true });
    
    const updatedDoc = await getDoc(userRef);
    if (!updatedDoc.exists()) {
      throw new Error('Failed to fetch updated profile');
    }

    return { id: updatedDoc.id, ...updatedDoc.data() } as User;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};