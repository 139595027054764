import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { User } from '../../types';
import { getFriends } from '../../services/friends';
import { createChat } from '../../services/messages';
import { useAuthStore } from '../../store/useAuthStore';

interface NewChatModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function NewChatModal({ isOpen, onClose }: NewChatModalProps) {
  const { user } = useAuthStore();
  const [friends, setFriends] = useState<User[]>([]);
  const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
  const [chatName, setChatName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      loadFriends();
    }
  }, [user]);

  const loadFriends = async () => {
    if (!user) return;
    try {
      const friendsList = await getFriends(user.id);
      setFriends(friendsList);
    } catch (error) {
      console.error('Error loading friends:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || selectedFriends.length === 0) return;

    setLoading(true);
    try {
      const isGroupChat = selectedFriends.length > 1;
      await createChat(
        [user.id, ...selectedFriends],
        isGroupChat,
        isGroupChat ? chatName : undefined
      );
      onClose();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
    setLoading(false);
  };

  const toggleFriend = (friendId: string) => {
    setSelectedFriends(prev =>
      prev.includes(friendId)
        ? prev.filter(id => id !== friendId)
        : [...prev, friendId]
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6 w-full">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
            New Conversation
          </Dialog.Title>

          <form onSubmit={handleSubmit} className="space-y-4">
            {selectedFriends.length > 1 && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Group Name
                </label>
                <input
                  type="text"
                  value={chatName}
                  onChange={(e) => setChatName(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#0066FF] focus:ring focus:ring-[#0066FF] focus:ring-opacity-50"
                  required={selectedFriends.length > 1}
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Friends
              </label>
              <div className="space-y-2 max-h-60 overflow-y-auto">
                {friends.map(friend => (
                  <label
                    key={friend.id}
                    className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={selectedFriends.includes(friend.id)}
                      onChange={() => toggleFriend(friend.id)}
                      className="rounded border-gray-300 text-[#0066FF] focus:ring-[#0066FF]"
                    />
                    <div className="flex items-center space-x-2">
                      <img
                        src={friend.avatar || '/default-avatar.png'}
                        alt={friend.name}
                        className="w-8 h-8 rounded-full"
                      />
                      <span>{friend.name}</span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading || selectedFriends.length === 0}
                className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC] disabled:opacity-50"
              >
                {loading ? 'Creating...' : 'Create Chat'}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}