import React from 'react';
import { useAuthStore } from '../store/useAuthStore';
import FriendsList from '../components/social/FriendsList';
import CreatePost from '../components/social/CreatePost';
import SocialFeed from '../components/social/SocialFeed';

function Social() {
  const { user } = useAuthStore();

  if (!user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <p className="text-center text-gray-600">Please sign in to access the social feed.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1">
          <FriendsList />
        </div>

        <div className="lg:col-span-2">
          <CreatePost />
          <SocialFeed />
        </div>
      </div>
    </div>
  );
}

export default Social;