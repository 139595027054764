import { collection, addDoc, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { Business, Activity } from '../types/core';

export const createBusiness = async (businessData: Omit<Business, 'id'>): Promise<Business> => {
  try {
    const docRef = await addDoc(collection(db, 'businesses'), {
      ...businessData,
      createdAt: new Date().toISOString()
    });
    return { id: docRef.id, ...businessData };
  } catch (error: any) {
    throw new Error(error.message || 'Failed to create business');
  }
};

export const getBusinessesByOwner = async (ownerId: string): Promise<Business[]> => {
  try {
    const q = query(collection(db, 'businesses'), where('ownerId', '==', ownerId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Business));
  } catch (error: any) {
    throw new Error(error.message || 'Failed to fetch businesses');
  }
};

export const updateBusiness = async (businessId: string, updates: Partial<Business>): Promise<void> => {
  try {
    const businessRef = doc(db, 'businesses', businessId);
    await updateDoc(businessRef, {
      ...updates,
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to update business');
  }
};

export const uploadBusinessImages = async (businessId: string, images: File[]): Promise<string[]> => {
  try {
    const uploadPromises = images.map(async (image) => {
      const storageRef = ref(storage, `businesses/${businessId}/${Date.now()}_${image.name}`);
      const snapshot = await uploadBytes(storageRef, image);
      return getDownloadURL(snapshot.ref);
    });

    return await Promise.all(uploadPromises);
  } catch (error: any) {
    throw new Error(error.message || 'Failed to upload images');
  }
};

export const createPaidEvent = async (
  eventData: Omit<Activity, 'id' | 'images'> & { businessId: string },
  images?: File[]
): Promise<Activity> => {
  try {
    const imageUrls = images ? await uploadBusinessImages(eventData.businessId, images) : [];
    
    const docRef = await addDoc(collection(db, 'activities'), {
      ...eventData,
      images: imageUrls,
      createdAt: new Date().toISOString()
    });

    return { id: docRef.id, ...eventData, images: imageUrls };
  } catch (error: any) {
    throw new Error(error.message || 'Failed to create paid event');
  }
};