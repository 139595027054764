import { useState, useEffect } from 'react';
import { useAuthStore } from '../store/useAuthStore';
import { getUserLists, removeActivityFromList } from '../services/lists';
import { getActivity } from '../services/activities';
import { Activity } from '../types';

interface SavedList {
  id: string;
  name: string;
  activities: Activity[];
}

export default function SavedLists() {
  const { user } = useAuthStore();
  const [lists, setLists] = useState<SavedList[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      loadLists();
    }
  }, [user]);

  const loadLists = async () => {
    if (!user) return;
    setLoading(true);
    try {
      const userLists = await getUserLists(user.id);
      
      // Load activity details for each list
      const listsWithActivities = await Promise.all(
        userLists.map(async (list) => {
          const activities = await Promise.all(
            list.activities.map(id => getActivity(id))
          );
          return {
            id: list.id,
            name: list.name,
            activities
          };
        })
      );
      
      setLists(listsWithActivities);
    } catch (error: any) {
      setError(error.message || 'Failed to load lists');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveActivity = async (listId: string, activityId: string) => {
    try {
      await removeActivityFromList(listId, activityId);
      await loadLists();
    } catch (error: any) {
      setError(error.message || 'Failed to remove activity');
    }
  };

  if (!user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <p className="text-center text-gray-600">Please sign in to view your saved lists.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Saved Lists</h1>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md mb-6">
          {error}
        </div>
      )}

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0066FF]"></div>
        </div>
      ) : (
        <div className="space-y-8">
          {lists.map(list => (
            <div key={list.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-4 border-b">
                <h2 className="text-xl font-semibold">{list.name}</h2>
                <p className="text-gray-500 text-sm mt-1">
                  {list.activities.length} activities
                </p>
              </div>

              <div className="p-4">
                {list.activities.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {list.activities.map(activity => (
                      <div key={activity.id} className="bg-gray-50 rounded-lg p-4">
                        {activity.images?.[0] && (
                          <img
                            src={activity.images[0]}
                            alt={activity.name}
                            className="w-full h-32 object-cover rounded-md mb-3"
                          />
                        )}
                        <h3 className="font-medium mb-1">{activity.name}</h3>
                        <p className="text-sm text-gray-500 mb-2">{activity.category}</p>
                        <div className="flex justify-between items-center">
                          <span className="text-[#0066FF]">⭐ {activity.rating}</span>
                          <button
                            onClick={() => handleRemoveActivity(list.id, activity.id)}
                            className="text-red-600 hover:text-red-700 text-sm"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-4">
                    No activities in this list yet
                  </p>
                )}
              </div>
            </div>
          ))}

          {lists.length === 0 && (
            <div className="text-center py-8">
              <p className="text-gray-500">You haven't created any lists yet.</p>
              <p className="text-gray-500 mt-2">
                Start by saving activities to a new list!
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}