import React, { useState, useEffect } from 'react';
import { User } from '../../types';
import { getFriends, searchUsers, addFriend, removeFriend } from '../../services/friends';
import { useAuthStore } from '../../store/useAuthStore';

export default function FriendsList() {
  const { user } = useAuthStore();
  const [friends, setFriends] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      loadFriends();
    }
  }, [user]);

  const loadFriends = async () => {
    if (!user) return;
    try {
      const friendsList = await getFriends(user.id);
      setFriends(friendsList);
    } catch (error) {
      console.error('Error loading friends:', error);
    }
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const results = await searchUsers(term);
      setSearchResults(results.filter(result => result.id !== user?.id));
    } catch (error) {
      console.error('Error searching users:', error);
    }
    setLoading(false);
  };

  const handleAddFriend = async (friendId: string) => {
    if (!user) return;
    try {
      await addFriend(user.id, friendId);
      await loadFriends();
      setSearchTerm('');
      setSearchResults([]);
    } catch (error) {
      console.error('Error adding friend:', error);
    }
  };

  const handleRemoveFriend = async (friendId: string) => {
    if (!user) return;
    try {
      await removeFriend(user.id, friendId);
      await loadFriends();
    } catch (error) {
      console.error('Error removing friend:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-xl font-semibold mb-4">Friends</h2>
      
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-2 border rounded-md"
        />
        
        {loading && (
          <div className="mt-2 text-center">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#0066FF] mx-auto"></div>
          </div>
        )}

        {searchResults.length > 0 && (
          <div className="mt-2 border rounded-md divide-y">
            {searchResults.map(result => (
              <div key={result.id} className="p-2 flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <img
                    src={result.avatar || '/default-avatar.png'}
                    alt={result.name}
                    className="w-8 h-8 rounded-full"
                  />
                  <span>{result.name}</span>
                </div>
                <button
                  onClick={() => handleAddFriend(result.id)}
                  className="text-sm text-[#0066FF] hover:text-[#0052CC]"
                >
                  Add Friend
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="space-y-4">
        {friends.map(friend => (
          <div key={friend.id} className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <img
                src={friend.avatar || '/default-avatar.png'}
                alt={friend.name}
                className="w-10 h-10 rounded-full"
              />
              <span className="text-gray-700">{friend.name}</span>
            </div>
            <button
              onClick={() => handleRemoveFriend(friend.id)}
              className="text-sm text-red-600 hover:text-red-700"
            >
              Remove
            </button>
          </div>
        ))}
        
        {friends.length === 0 && (
          <p className="text-gray-500 text-center">No friends added yet</p>
        )}
      </div>
    </div>
  );
}