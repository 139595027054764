import { useState } from 'react';
import { Activity } from '../../types';
import { formatDistance } from '../../utils/location';
import { Badge } from '../common';

interface ActivityCardProps {
  activity: Activity;
  userLocation?: { lat: number; lng: number };
  onSaveToList: (activityId: string) => void;
}

export default function ActivityCard({ activity, userLocation, onSaveToList }: ActivityCardProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const distance = userLocation && activity.location.coordinates
    ? formatDistance(userLocation, activity.location.coordinates)
    : null;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
      {activity.images?.[0] && (
        <div className="relative h-48">
          {!imageLoaded && (
            <div className="absolute inset-0 bg-gray-100 animate-pulse" />
          )}
          <img
            src={activity.images[0]}
            alt={activity.name}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
          />
          <button
            onClick={() => onSaveToList(activity.id)}
            className="absolute top-2 right-2 bg-white/90 hover:bg-white p-2 rounded-full shadow-md transition-all duration-200 hover:scale-110"
            title="Save to list"
          >
            <span role="img" aria-label="save" className="text-[#0066FF]">
              📌
            </span>
          </button>
        </div>
      )}
      
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-semibold text-gray-900 hover:text-[#0066FF] transition-colors">
            {activity.name}
          </h3>
          <span className="flex items-center text-[#0066FF] font-medium">
            <span className="mr-1">⭐</span>
            {activity.rating}
          </span>
        </div>
        
        <p className="text-gray-600 text-sm mb-3 line-clamp-2">
          {activity.description}
        </p>
        
        <div className="flex items-center justify-between text-sm">
          <Badge variant="primary">
            {activity.category}
          </Badge>
          {distance && (
            <span className="text-gray-500 font-medium">
              📍 {distance}
            </span>
          )}
        </div>

        {activity.price && (
          <div className="mt-2 text-sm font-medium text-gray-700">
            From £{activity.price}
          </div>
        )}
      </div>
    </div>
  );
}