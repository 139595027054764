import { useState, useEffect } from 'react';
import { useAuthStore } from '../../store/useAuthStore';
import { getFeedPosts, likePost, unlikePost, addComment } from '../../services/social';
import { Post } from '../../types/core';
import { format } from 'date-fns';
import { LoadingSpinner, ErrorMessage, Button } from '../common';
import PostCard from './PostCard';
import CommentForm from './CommentForm';

export default function SocialFeed() {
  const { user } = useAuthStore();
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [commentingPostId, setCommentingPostId] = useState<string | null>(null);

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async () => {
    if (!user) return;
    setLoading(true);
    try {
      const feedPosts = await getFeedPosts(user.id);
      setPosts(feedPosts);
      setError(null);
    } catch (error) {
      console.error('Error loading posts:', error);
      setError('Failed to load posts');
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (postId: string) => {
    if (!user) return;
    try {
      await likePost(postId, user.id);
      await loadPosts();
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleUnlike = async (postId: string) => {
    if (!user) return;
    try {
      await unlikePost(postId, user.id);
      await loadPosts();
    } catch (error) {
      console.error('Error unliking post:', error);
    }
  };

  const handleComment = async (postId: string, content: string) => {
    if (!user || !content.trim()) return;
    try {
      await addComment(postId, {
        userId: user.id,
        content: content.trim(),
        createdAt: new Date().toISOString()
      });
      setCommentingPostId(null);
      await loadPosts();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <div className="space-y-6">
      {posts.map(post => (
        <PostCard
          key={post.id}
          post={post}
          currentUserId={user?.id}
          onLike={() => handleLike(post.id)}
          onUnlike={() => handleUnlike(post.id)}
          onCommentClick={() => setCommentingPostId(post.id)}
        >
          {commentingPostId === post.id && (
            <CommentForm
              onSubmit={(content) => handleComment(post.id, content)}
              onCancel={() => setCommentingPostId(null)}
            />
          )}
        </PostCard>
      ))}

      {posts.length === 0 && (
        <p className="text-center text-gray-500">No posts to show</p>
      )}
    </div>
  );
}