import { useState } from 'react';
import { Button } from '../common';

interface CommentFormProps {
  onSubmit: (content: string) => void;
  onCancel: () => void;
}

export default function CommentForm({ onSubmit, onCancel }: CommentFormProps) {
  const [content, setContent] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim()) return;
    onSubmit(content);
    setContent('');
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Write a comment..."
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-[#0066FF] focus:ring focus:ring-[#0066FF] focus:ring-opacity-50"
        rows={3}
      />
      <div className="mt-2 flex justify-end space-x-2">
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={!content.trim()}
        >
          Post Comment
        </Button>
      </div>
    </form>
  );
}