import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { User } from '../types/core';

// Add retry logic for network issues
const withRetry = async <T>(fn: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await fn();
  } catch (error: any) {
    if (retries > 0 && error.code === 'auth/network-request-failed') {
      // Wait 1 second before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));
      return withRetry(fn, retries - 1);
    }
    throw error;
  }
};

export const signIn = async (email: string, password: string): Promise<User> => {
  try {
    const { user: firebaseUser } = await withRetry(() => 
      signInWithEmailAndPassword(auth, email, password)
    );

    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    
    if (!userDoc.exists()) {
      const newUser: User = {
        id: firebaseUser.uid,
        name: firebaseUser.displayName || email.split('@')[0],
        email: firebaseUser.email || email,
        savedActivities: [],
        savedLists: [],
        friends: [],
        avatar: firebaseUser.photoURL,
        bio: ''
      };

      await setDoc(doc(db, 'users', newUser.id), newUser);
      return newUser;
    }
    
    return { id: userDoc.id, ...userDoc.data() } as User;
  } catch (error: any) {
    console.error('Sign in error:', error);
    if (error.code === 'auth/network-request-failed') {
      throw new Error('Network error. Please check your connection and try again.');
    }
    throw new Error(error.message || 'Failed to sign in');
  }
};

export const signUp = async (email: string, password: string, name: string): Promise<User> => {
  try {
    const { user: firebaseUser } = await withRetry(() =>
      createUserWithEmailAndPassword(auth, email, password)
    );

    const newUser: User = {
      id: firebaseUser.uid,
      name,
      email,
      savedActivities: [],
      savedLists: [],
      friends: [],
      avatar: null,
      bio: ''
    };

    await setDoc(doc(db, 'users', newUser.id), newUser);
    return newUser;
  } catch (error: any) {
    console.error('Sign up error:', error);
    throw new Error(error.message || 'Failed to create account');
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    throw new Error(error.message || 'Failed to sign out');
  }
};