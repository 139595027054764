import React, { useState, useEffect } from 'react';
import { Chat, User } from '../../types';
import { getChats } from '../../services/messages';
import { useAuthStore } from '../../store/useAuthStore';
import { format } from 'date-fns';

interface ChatListProps {
  onSelectChat: (chat: Chat) => void;
  selectedChatId?: string;
}

export default function ChatList({ onSelectChat, selectedChatId }: ChatListProps) {
  const { user } = useAuthStore();
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadChats();
    }
  }, [user]);

  const loadChats = async () => {
    if (!user) return;
    try {
      const userChats = await getChats(user.id);
      setChats(userChats);
    } catch (error) {
      console.error('Error loading chats:', error);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#0066FF]"></div>
      </div>
    );
  }

  return (
    <div className="divide-y">
      {chats.map(chat => (
        <button
          key={chat.id}
          onClick={() => onSelectChat(chat)}
          className={`w-full p-4 text-left hover:bg-gray-50 ${
            selectedChatId === chat.id ? 'bg-blue-50' : ''
          }`}
        >
          <div className="flex items-center space-x-3">
            {chat.isGroupChat ? (
              <div className="w-12 h-12 bg-[#0066FF] rounded-full flex items-center justify-center text-white">
                {chat.name?.[0] || 'G'}
              </div>
            ) : (
              <img
                src="/default-avatar.png"
                alt="Chat"
                className="w-12 h-12 rounded-full"
              />
            )}
            <div className="flex-1 min-w-0">
              <p className="font-medium truncate">
                {chat.name || 'Chat'}
              </p>
              {chat.messages && chat.messages.length > 0 && (
                <p className="text-sm text-gray-500 truncate">
                  {chat.messages[chat.messages.length - 1].content}
                </p>
              )}
            </div>
            {chat.messages && chat.messages.length > 0 && (
              <span className="text-xs text-gray-500">
                {format(new Date(chat.messages[chat.messages.length - 1].createdAt), 'HH:mm')}
              </span>
            )}
          </div>
        </button>
      ))}

      {chats.length === 0 && (
        <p className="text-center text-gray-500 py-4">No conversations yet</p>
      )}
    </div>
  );
}