import { format } from 'date-fns';
import { Post } from '../../types/core';
import { Button } from '../common';

interface PostCardProps {
  post: Post;
  currentUserId?: string;
  onLike: () => void;
  onUnlike: () => void;
  onCommentClick: () => void;
  children?: React.ReactNode;
}

export default function PostCard({
  post,
  currentUserId,
  onLike,
  onUnlike,
  onCommentClick,
  children
}: PostCardProps) {
  const isLiked = post.likes.includes(currentUserId || '');

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex items-center space-x-3 mb-4">
        <img
          src="/default-avatar.png"
          alt="User avatar"
          className="w-10 h-10 rounded-full"
        />
        <div>
          <h3 className="font-semibold">Anonymous User</h3>
          <span className="text-gray-500 text-sm">
            {format(new Date(post.createdAt), 'MMM d, yyyy h:mm a')}
          </span>
        </div>
      </div>

      <div className="mb-4">
        <div className="bg-blue-50 rounded-md p-2 mb-2">
          <p className="text-sm text-blue-600">
            Activity: {post.activityName}
          </p>
          <div className="flex items-center mt-1">
            <span className="text-yellow-400">
              {'★'.repeat(post.rating)}
            </span>
            <span className="text-gray-300">
              {'★'.repeat(5 - post.rating)}
            </span>
          </div>
        </div>
        <p className="text-gray-700">{post.content}</p>
      </div>

      {post.images && post.images.length > 0 && (
        <div className="grid grid-cols-2 gap-2 mb-4">
          {post.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Post image ${index + 1}`}
              className="rounded-md w-full h-48 object-cover"
            />
          ))}
        </div>
      )}

      <div className="flex items-center space-x-4">
        <Button
          variant="secondary"
          size="sm"
          onClick={isLiked ? onUnlike : onLike}
          className={isLiked ? 'text-[#0066FF]' : ''}
        >
          ❤️ {post.likes.length} Likes
        </Button>
        
        <Button
          variant="secondary"
          size="sm"
          onClick={onCommentClick}
        >
          💬 {post.comments.length} Comments
        </Button>
      </div>

      {post.comments.length > 0 && (
        <div className="mt-4 space-y-2">
          {post.comments.map(comment => (
            <div key={comment.id} className="bg-gray-50 p-3 rounded-md">
              <div className="flex items-center space-x-2 mb-1">
                <img
                  src="/default-avatar.png"
                  alt="Commenter avatar"
                  className="w-6 h-6 rounded-full"
                />
                <span className="font-medium text-sm">Anonymous User</span>
              </div>
              <p className="text-gray-700">{comment.content}</p>
              <span className="text-xs text-gray-500">
                {format(new Date(comment.createdAt), 'MMM d, yyyy h:mm a')}
              </span>
            </div>
          ))}
        </div>
      )}

      {children}
    </div>
  );
}