import React from 'react';
import ActivitySearch from '../components/activities/ActivitySearch';

function Activities() {
  return (
    <div className="container mx-auto px-4">
      <ActivitySearch />
    </div>
  );
}

export default Activities;