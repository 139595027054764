import { useState } from 'react';
import { useAuthStore } from '../../store/useAuthStore';
import { createPost } from '../../services/social';
import { Activity } from '../../types/core';
import { searchActivities } from '../../services/activities';
import { Dialog } from '@headlessui/react';
import { StarRating } from '../common';

export default function CreatePost() {
  const { user } = useAuthStore();
  const [content, setContent] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Activity[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
  const [rating, setRating] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const results = await searchActivities(term);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching activities:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !content.trim() || !selectedActivity || rating === 0) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await createPost({
        userId: user.id,
        content: content.trim(),
        activityId: selectedActivity.id,
        activityName: selectedActivity.name,
        rating,
        createdAt: new Date().toISOString()
      }, images);
      
      setContent('');
      setImages([]);
      setSelectedActivity(null);
      setRating(0);
    } catch (err) {
      setError('Failed to create post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  const removeImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  if (!user) return null;

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <form onSubmit={handleSubmit}>
        {error && (
          <p className="text-red-600 mb-4 text-sm">{error}</p>
        )}
        
        <div className="mb-4">
          <button
            type="button"
            onClick={() => setIsSearchOpen(true)}
            className="w-full p-3 border rounded-md text-left text-gray-600 hover:border-[#0066FF] transition-colors"
          >
            {selectedActivity ? selectedActivity.name : 'Tag an activity (required)'}
          </button>
        </div>

        {selectedActivity && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Rate your experience
            </label>
            <StarRating rating={rating} onChange={setRating} size="md" />
          </div>
        )}
        
        <textarea
          placeholder="Share your experience..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full p-3 border rounded-md resize-none focus:border-[#0066FF] focus:ring focus:ring-[#0066FF] focus:ring-opacity-50"
          rows={3}
        />

        {images.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className="w-20 h-20 object-cover rounded-md"
                />
                <button
                  type="button"
                  onClick={() => removeImage(index)}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-sm"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="mt-4 flex justify-between items-center">
          <label className="cursor-pointer text-[#0066FF] hover:text-[#0052CC] transition-colors">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              className="hidden"
            />
            📷 Add Photos
          </label>
          
          <button
            type="submit"
            disabled={loading || !content.trim() || !selectedActivity || rating === 0}
            className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            {loading ? 'Posting...' : 'Post'}
          </button>
        </div>
      </form>

      <Dialog open={isSearchOpen} onClose={() => setIsSearchOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6 w-full">
            <Dialog.Title className="text-lg font-medium mb-4">
              Search Activities
            </Dialog.Title>

            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search for an activity..."
              className="w-full p-2 border rounded-md mb-4"
            />

            <div className="max-h-60 overflow-y-auto">
              {searchResults.map(activity => (
                <button
                  key={activity.id}
                  onClick={() => {
                    setSelectedActivity(activity);
                    setIsSearchOpen(false);
                  }}
                  className="w-full text-left p-3 hover:bg-gray-50 rounded-md"
                >
                  <p className="font-medium">{activity.name}</p>
                  <p className="text-sm text-gray-500">{activity.category}</p>
                </button>
              ))}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}