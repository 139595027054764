import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useAuthStore } from '../../store/useAuthStore';
import { getUserLists, createList, addActivityToList } from '../../services/lists';
import type { ActivityList } from '../../types/core';

interface SaveListModalProps {
  activityId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function SaveListModal({ activityId, isOpen, onClose }: SaveListModalProps) {
  const { user } = useAuthStore();
  const [lists, setLists] = useState<ActivityList[]>([]);
  const [newListName, setNewListName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (isOpen && user) {
      loadLists();
    }
  }, [isOpen, user]);

  const loadLists = async () => {
    if (!user) return;
    try {
      const userLists = await getUserLists(user.id);
      setLists(userLists);
    } catch (error) {
      console.error('Error loading lists:', error);
      setError('Failed to load your lists');
    }
  };

  const handleSaveToList = async (listId: string) => {
    if (!activityId || !user) return;
    setLoading(true);
    setError('');
    try {
      await addActivityToList(listId, activityId);
      setSuccess('Activity saved to list!');
      setTimeout(() => {
        onClose();
        setSuccess('');
      }, 1500);
    } catch (error) {
      setError('Failed to save activity to list');
    }
    setLoading(false);
  };

  const handleCreateList = async () => {
    if (!user || !newListName.trim() || !activityId) return;
    setLoading(true);
    setError('');
    try {
      const newList = await createList(user.id, newListName.trim());
      await addActivityToList(newList.id, activityId);
      setSuccess('New list created and activity saved!');
      setTimeout(() => {
        onClose();
        setSuccess('');
      }, 1500);
    } catch (error) {
      setError('Failed to create new list');
    }
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6 w-full">
          <Dialog.Title className="text-lg font-medium mb-4">
            Save to List
          </Dialog.Title>

          {error && (
            <p className="text-red-600 text-sm mb-4">{error}</p>
          )}

          {success && (
            <p className="text-green-600 text-sm mb-4">{success}</p>
          )}

          <div className="space-y-4">
            {lists.map(list => (
              <button
                key={list.id}
                onClick={() => handleSaveToList(list.id)}
                disabled={loading}
                className="w-full text-left p-3 hover:bg-gray-50 rounded-md transition-colors"
              >
                <p className="font-medium">{list.name}</p>
                <p className="text-sm text-gray-500">
                  {list.activities.length} activities
                </p>
              </button>
            ))}

            <div className="pt-4 border-t">
              <p className="text-sm font-medium text-gray-700 mb-2">
                Create New List
              </p>
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                  placeholder="List name"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-[#0066FF] focus:ring focus:ring-[#0066FF] focus:ring-opacity-50"
                />
                <button
                  onClick={handleCreateList}
                  disabled={loading || !newListName.trim()}
                  className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC] disabled:opacity-50 transition-colors"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}