import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import { getProfile } from './services/profile';
import { useAuthStore } from './store/useAuthStore';
import ErrorBoundary from './components/ErrorBoundary';
import Navigation from './components/layout/Navigation';
import Home from './pages/Home';
import Activities from './pages/Activities';
import Social from './pages/Social';
import Profile from './pages/Profile';
import BusinessPage from './pages/Business';
import Messages from './pages/Messages';
import SavedLists from './pages/SavedLists';

function App() {
  const { setUser } = useAuthStore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userProfile = await getProfile(firebaseUser.uid);
          if (userProfile) {
            setUser(userProfile);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Navigation />
          <main className="container mx-auto px-4 pt-20 pb-8">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/activities" element={<Activities />} />
              <Route path="/social" element={<Social />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/business" element={<BusinessPage />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/saved-lists" element={<SavedLists />} />
            </Routes>
          </main>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;