import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useAuthStore } from '../../store/useAuthStore';
import { createBusiness } from '../../services/business';
import { Business } from '../../types';

interface CreateBusinessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBusinessCreated: () => void;
}

export default function CreateBusinessModal({
  isOpen,
  onClose,
  onBusinessCreated
}: CreateBusinessModalProps) {
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setLoading(true);
    setError('');

    try {
      const businessData: Omit<Business, 'id'> = {
        ownerId: user.id,
        name,
        description,
        contact: { email, phone, website },
        location: { 
          address, 
          city, 
          state,
          coordinates: {
            lat: 0,
            lng: 0
          }
        },
        activities: [],
        images: []
      };

      await createBusiness(businessData);
      onBusinessCreated();
      onClose();
      resetForm();
    } catch (err) {
      setError('Failed to create business. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setEmail('');
    setPhone('');
    setWebsite('');
    setAddress('');
    setCity('');
    setState('');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Rest of the component remains unchanged */}
    </Dialog>
  );
}