import { useState, useEffect } from 'react';
import { Activity } from '../../types/core';
import { searchActivities } from '../../services/activities';
import { categories } from '../../utils/categories';
import ActivityCard from './ActivityCard';
import SaveListModal from './SaveListModal';

export default function ActivitySearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  useEffect(() => {
    loadActivities();
  }, [searchTerm, selectedCategory]);

  const loadActivities = async () => {
    setLoading(true);
    try {
      const results = await searchActivities(searchTerm, selectedCategory);
      setActivities(results);
    } catch (error) {
      console.error('Error loading activities:', error);
    }
    setLoading(false);
  };

  const handleSaveToList = (activityId: string) => {
    setSelectedActivityId(activityId);
    setIsSaveModalOpen(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-4">
        <h1 className="text-3xl font-bold text-gray-900">
          Discover Activities
        </h1>
        
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <input
            type="text"
            placeholder="Search activities..."
            className="flex-1 p-2 border rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="p-2 border rounded-md min-w-[200px]"
          >
            <option value="all">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category.toLowerCase()}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0066FF]"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {activities.map((activity) => (
            <ActivityCard
              key={activity.id}
              activity={activity}
              onSaveToList={handleSaveToList}
            />
          ))}
        </div>
      )}

      <SaveListModal
        activityId={selectedActivityId}
        isOpen={isSaveModalOpen}
        onClose={() => {
          setIsSaveModalOpen(false);
          setSelectedActivityId(null);
        }}
      />
    </div>
  );
}