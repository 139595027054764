import React, { useState } from 'react';
import { useAuthStore } from '../store/useAuthStore';
import { Chat } from '../types';
import ChatList from '../components/messages/ChatList';
import ChatWindow from '../components/messages/ChatWindow';
import NewChatModal from '../components/messages/NewChatModal';

function Messages() {
  const { user } = useAuthStore();
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false);

  if (!user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <p className="text-center text-gray-600">Please sign in to access messages.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-3 h-[calc(100vh-12rem)]">
          {/* Chat List */}
          <div className="border-r">
            <div className="p-4 border-b flex justify-between items-center">
              <h2 className="text-lg font-semibold">Messages</h2>
              <button
                onClick={() => setIsNewChatModalOpen(true)}
                className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC]"
              >
                New Chat
              </button>
            </div>
            <ChatList
              onSelectChat={setSelectedChat}
              selectedChatId={selectedChat?.id}
            />
          </div>

          {/* Chat Window */}
          <div className="md:col-span-2 flex flex-col">
            {selectedChat ? (
              <ChatWindow chat={selectedChat} />
            ) : (
              <div className="flex-1 flex items-center justify-center text-gray-500">
                Select a conversation or start a new one
              </div>
            )}
          </div>
        </div>
      </div>

      <NewChatModal
        isOpen={isNewChatModalOpen}
        onClose={() => setIsNewChatModalOpen(false)}
      />
    </div>
  );
}

export default Messages;