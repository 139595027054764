import { collection, addDoc, query, where, getDocs, updateDoc, doc, arrayUnion, arrayRemove, orderBy } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { Post, Comment } from '../types/core';

export const createPost = async (postData: Omit<Post, 'id' | 'likes' | 'comments'>, images?: File[]): Promise<Post> => {
  try {
    const imageUrls = images ? await Promise.all(
      images.map(async (image) => {
        const storageRef = ref(storage, `posts/${Date.now()}_${image.name}`);
        const snapshot = await uploadBytes(storageRef, image);
        return getDownloadURL(snapshot.ref);
      })
    ) : [];

    const post = {
      ...postData,
      images: imageUrls,
      likes: [],
      comments: [],
      createdAt: new Date().toISOString()
    };

    const docRef = await addDoc(collection(db, 'posts'), post);
    return { ...post, id: docRef.id };
  } catch (error: any) {
    throw new Error(error.message || 'Failed to create post');
  }
};

export const getFeedPosts = async (userId: string): Promise<Post[]> => {
  try {
    const q = query(
      collection(db, 'posts'),
      orderBy('createdAt', 'desc')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  } catch (error: any) {
    throw new Error(error.message || 'Failed to load posts');
  }
};

export const likePost = async (postId: string, userId: string): Promise<void> => {
  try {
    const postRef = doc(db, 'posts', postId);
    await updateDoc(postRef, {
      likes: arrayUnion(userId)
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to like post');
  }
};

export const unlikePost = async (postId: string, userId: string): Promise<void> => {
  try {
    const postRef = doc(db, 'posts', postId);
    await updateDoc(postRef, {
      likes: arrayRemove(userId)
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to unlike post');
  }
};

export const addComment = async (postId: string, comment: Omit<Comment, 'id'>): Promise<void> => {
  try {
    const postRef = doc(db, 'posts', postId);
    await updateDoc(postRef, {
      comments: arrayUnion({
        ...comment,
        id: Date.now().toString()
      })
    });
  } catch (error: any) {
    throw new Error(error.message || 'Failed to add comment');
  }
};