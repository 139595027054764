import { collection, query, where, getDocs, addDoc, orderBy, limit, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Message, Chat } from '../types';

export const createChat = async (participants: string[], isGroupChat: boolean = false, name?: string): Promise<Chat> => {
  const chat = {
    participants,
    isGroupChat,
    name,
    messages: [],
    createdAt: new Date().toISOString()
  };
  
  const docRef = await addDoc(collection(db, 'chats'), chat);
  return { id: docRef.id, ...chat };
};

export const sendMessage = async (chatId: string, senderId: string, content: string): Promise<Message> => {
  const message = {
    senderId,
    content,
    createdAt: new Date().toISOString(),
    readBy: [senderId]
  };
  
  const docRef = await addDoc(collection(db, `chats/${chatId}/messages`), message);
  return { id: docRef.id, ...message };
};

export const getChats = async (userId: string): Promise<Chat[]> => {
  const chatsRef = collection(db, 'chats');
  const q = query(
    chatsRef,
    where('participants', 'array-contains', userId),
    orderBy('createdAt', 'desc')
  );
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Chat));
};

export const getChatMessages = async (chatId: string, messageLimit: number = 50): Promise<Message[]> => {
  const messagesRef = collection(db, `chats/${chatId}/messages`);
  const q = query(
    messagesRef,
    orderBy('createdAt', 'desc'),
    limit(messageLimit)
  );
  
  const snapshot = await getDocs(q);
  return snapshot.docs
    .map(doc => ({ id: doc.id, ...doc.data() } as Message))
    .reverse();
};

export const markMessageAsRead = async (chatId: string, messageId: string, userId: string) => {
  const messageRef = doc(db, `chats/${chatId}/messages/${messageId}`);
  await updateDoc(messageRef, {
    readBy: arrayUnion(userId)
  });
};