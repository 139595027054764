import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/useAuthStore';
import SignInModal from '../auth/SignInModal';
import SignUpModal from '../auth/SignUpModal';
import { signOut } from '../../services/auth';
import { Menu } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

function Navigation() {
  const { isAuthenticated, user } = useAuthStore();
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSignOut = async () => {
    await signOut();
    useAuthStore.getState().logout();
  };

  const menuItems = [
    { path: '/activities', label: 'Find Activities', icon: '🔍' },
    { path: '/social', label: 'Social Feed', icon: '👥' },
    { path: '/messages', label: 'Messages', icon: '💬' },
    { path: '/business', label: 'Business', icon: '💼' },
    { path: '/saved-lists', label: 'Saved Lists', icon: '📋' },
    { path: '/profile', label: 'Profile', icon: '👤' }
  ];

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 bg-white transition-shadow duration-300 ${
      isScrolled ? 'shadow-md' : ''
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-3">
            <span className="text-2xl font-bold text-[#0066FF]">
              Explore More
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            {menuItems.map(item => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-gray-700 hover:text-[#0066FF] transition-colors ${
                  location.pathname === item.path ? 'text-[#0066FF] font-medium' : ''
                }`}
              >
                {item.label}
              </Link>
            ))}

            {isAuthenticated ? (
              <Menu as="div" className="relative">
                <Menu.Button className="flex items-center space-x-2 group">
                  <img 
                    src={user?.avatar || '/default-avatar.png'} 
                    alt="Profile" 
                    className="w-8 h-8 rounded-full ring-2 ring-white group-hover:ring-[#0066FF]"
                  />
                </Menu.Button>

                <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleSignOut}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } flex items-center w-full px-4 py-2 text-sm text-red-600`}
                      >
                        <span className="mr-2">🚪</span>
                        Sign Out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            ) : (
              <div className="flex space-x-3">
                <button 
                  onClick={() => setIsSignInOpen(true)}
                  className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC] transition-colors"
                >
                  Sign In
                </button>
                <button 
                  onClick={() => setIsSignUpOpen(true)}
                  className="border-2 border-[#0066FF] text-[#0066FF] px-4 py-2 rounded-md hover:bg-blue-50 transition-colors"
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-md text-gray-700 hover:text-[#0066FF] hover:bg-blue-50"
          >
            {isMobileMenuOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3Icon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white border-t">
          <div className="px-4 pt-2 pb-3 space-y-1">
            {menuItems.map(item => (
              <Link
                key={item.path}
                to={item.path}
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  location.pathname === item.path
                    ? 'text-[#0066FF] bg-blue-50'
                    : 'text-gray-700 hover:text-[#0066FF] hover:bg-blue-50'
                }`}
              >
                <span className="mr-2">{item.icon}</span>
                {item.label}
              </Link>
            ))}

            {!isAuthenticated && (
              <div className="pt-4 space-y-2">
                <button
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setIsSignInOpen(true);
                  }}
                  className="w-full bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC]"
                >
                  Sign In
                </button>
                <button
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setIsSignUpOpen(true);
                  }}
                  className="w-full border-2 border-[#0066FF] text-[#0066FF] px-4 py-2 rounded-md hover:bg-blue-50"
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <SignInModal 
        isOpen={isSignInOpen} 
        onClose={() => setIsSignInOpen(false)} 
      />
      <SignUpModal 
        isOpen={isSignUpOpen} 
        onClose={() => setIsSignUpOpen(false)}
        onSwitchToSignIn={() => {
          setIsSignUpOpen(false);
          setIsSignInOpen(true);
        }}
      />
    </nav>
  );
}

export default Navigation;