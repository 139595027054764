import { useState } from 'react';
import { StarIcon as StarOutline } from '@heroicons/react/24/outline';
import { StarIcon as StarSolid } from '@heroicons/react/24/solid';

interface StarRatingProps {
  rating: number;
  onChange: (rating: number) => void;
  size?: 'sm' | 'md' | 'lg';
}

export default function StarRating({ rating, onChange, size = 'md' }: StarRatingProps) {
  const [hoverRating, setHoverRating] = useState<number | null>(null);

  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
    lg: 'w-8 h-8'
  };

  return (
    <div className="flex items-center gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          type="button"
          onClick={() => onChange(star)}
          onMouseEnter={() => setHoverRating(star)}
          onMouseLeave={() => setHoverRating(null)}
          className="p-1 transition-transform hover:scale-110 focus:outline-none"
        >
          {(hoverRating !== null ? star <= hoverRating : star <= rating) ? (
            <StarSolid 
              className={`${sizeClasses[size]} text-yellow-400`}
              aria-hidden="true"
            />
          ) : (
            <StarOutline
              className={`${sizeClasses[size]} text-gray-300 hover:text-yellow-200`}
              aria-hidden="true"
            />
          )}
          <span className="sr-only">{star} stars</span>
        </button>
      ))}
      <span className="ml-2 text-sm text-gray-600">
        {rating > 0 ? `${rating} out of 5 stars` : 'Rate your experience'}
      </span>
    </div>
  );
}