import React, { useState } from 'react';
import { Business } from '../../types';
import { updateBusiness } from '../../services/business';
import BusinessEditModal from './BusinessEditModal';

interface BusinessListProps {
  businesses: Business[];
  onUpdate: () => void;
}

export default function BusinessList({ businesses, onUpdate }: BusinessListProps) {
  const [editingBusiness, setEditingBusiness] = useState<Business | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleUpdateBusiness = async (businessId: string, updates: Partial<Business>) => {
    setLoading(true);
    setError('');
    try {
      await updateBusiness(businessId, updates);
      onUpdate();
      setEditingBusiness(null);
    } catch (error) {
      console.error('Error updating business:', error);
      setError('Failed to update business');
    }
    setLoading(false);
  };

  if (businesses.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">You haven't added any businesses yet.</p>
      </div>
    );
  }

  return (
    <>
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md mb-4">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {businesses.map(business => (
          <div key={business.id} className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {business.name}
            </h3>
            <p className="text-gray-600 mb-4">{business.description}</p>
            
            <div className="space-y-2">
              <div className="flex items-center text-gray-600">
                <span className="w-20 flex-shrink-0">Email:</span>
                <span>{business.contact.email}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <span className="w-20 flex-shrink-0">Phone:</span>
                <span>{business.contact.phone}</span>
              </div>
              {business.contact.website && (
                <div className="flex items-center text-gray-600">
                  <span className="w-20 flex-shrink-0">Website:</span>
                  <a 
                    href={business.contact.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#0066FF] hover:underline"
                  >
                    {business.contact.website}
                  </a>
                </div>
              )}
            </div>

            <div className="mt-4 pt-4 border-t">
              <h4 className="font-medium text-gray-900 mb-2">Location</h4>
              <address className="text-gray-600 not-italic">
                {business.location.address}<br />
                {business.location.city}, {business.location.state}
              </address>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setEditingBusiness(business)}
                className="text-[#0066FF] hover:text-[#0052CC] font-medium"
                disabled={loading}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>

      {editingBusiness && (
        <BusinessEditModal
          business={editingBusiness}
          onClose={() => setEditingBusiness(null)}
          onUpdate={handleUpdateBusiness}
          isLoading={loading}
        />
      )}
    </>
  );
}