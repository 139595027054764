import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Enable persistence with better error handling
export const initializeFirebase = async () => {
  try {
    // Enable auth persistence first
    await setPersistence(auth, browserLocalPersistence);
    
    // Enable Firestore offline persistence with fallback
    try {
      await enableIndexedDbPersistence(db, {
        forceOwnership: true // This helps with multi-tab issues
      });
      console.log('Firestore persistence enabled');
    } catch (err: any) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled in one tab at a time
        console.warn('Multiple tabs open, persistence enabled in first tab only');
      } else if (err.code === 'unimplemented') {
        // The current browser doesn't support persistence
        console.warn('Browser does not support persistence');
      } else {
        console.error('Persistence initialization error:', err);
      }
    }

    // Initialize auth state listener
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User is signed in');
      } else {
        console.log('User is signed out');
      }
    });

    return true;
  } catch (error) {
    console.error('Firebase initialization error:', error);
    return false;
  }
};

export default app;