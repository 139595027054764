import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center py-16 sm:py-20">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block">Discover Your Next</span>
          <span className="block text-[#0066FF]">Adventure</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          From local hidden gems to exciting activities, Explore More helps you discover 
          amazing experiences in your area. Start your journey today!
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <Link
              to="/activities"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#0066FF] hover:bg-[#0052CC] md:py-4 md:text-lg md:px-10"
            >
              Start Exploring
            </Link>
          </div>
        </div>
      </div>

      {/* Featured Categories */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-gray-900 text-center mb-8">
          Popular Categories
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {[
            { name: 'Coffee Shops', icon: '☕' },
            { name: 'Rock Climbing', icon: '🧗‍♂️' },
            { name: 'Restaurants', icon: '🍽️' },
            { name: 'Outdoor Activities', icon: '🏃‍♂️' },
          ].map((category) => (
            <Link
              key={category.name}
              to={`/activities?category=${category.name.toLowerCase()}`}
              className="group bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-shadow"
            >
              <span className="text-4xl mb-4 block">{category.icon}</span>
              <h3 className="text-lg font-medium text-gray-900 group-hover:text-[#0066FF]">
                {category.name}
              </h3>
            </Link>
          ))}
        </div>
      </div>

      {/* How It Works */}
      <div className="mt-24">
        <h2 className="text-2xl font-bold text-gray-900 text-center mb-12">
          How It Works
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: 'Discover',
              description: 'Find amazing activities and experiences near you',
              icon: '🔍',
            },
            {
              title: 'Connect',
              description: 'Share experiences with friends and meet new people',
              icon: '👥',
            },
            {
              title: 'Explore',
              description: 'Try new things and create lasting memories',
              icon: '🌟',
            },
          ].map((step, index) => (
            <div key={index} className="text-center">
              <span className="text-4xl mb-4 block">{step.icon}</span>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {step.title}
              </h3>
              <p className="text-gray-500">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;