export const categories = [
  'Coffee Shops',
  'Rock Climbing',
  'Bowling',
  'Golf',
  'Restaurants',
  'Outdoor Activities',
  'Sports',
  'Museums',
  'Art Galleries',
  'Movie Theaters',
  'Live Music',
  'Bars & Pubs',
  'Shopping',
  'Parks',
  'Fitness Centers',
  'Spas',
  'Arcades',
  'Mini Golf',
  'Water Sports',
  'Hiking Trails',
  'Bike Rentals',
  'Escape Rooms',
  'Cooking Classes',
  'Dance Studios',
  'Yoga Studios',
  'Ice Skating',
  'Roller Skating',
  'Go-Kart Racing',
  'Paintball',
  'Laser Tag',
  'Axe Throwing',
  'Board Game Cafes',
  'Virtual Reality',
  'Trampoline Parks',
  'Zoos & Aquariums',
  'Botanical Gardens',
  'Historical Sites',
  'Wine Tasting',
  'Brewery Tours'
] as const;

export type Category = typeof categories[number];