import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/useAuthStore';
import { Business as BusinessType } from '../types';
import { getBusinessesByOwner } from '../services/business';
import CreateBusinessModal from '../components/business/CreateBusinessModal';
import BusinessList from '../components/business/BusinessList';
import CreateEventModal from '../components/business/CreateEventModal';

function BusinessPage() {
  const { user } = useAuthStore();
  const [businesses, setBusinesses] = useState<BusinessType[]>([]);
  const [isCreateBusinessOpen, setIsCreateBusinessOpen] = useState(false);
  const [isCreateEventOpen, setIsCreateEventOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadBusinesses();
    }
  }, [user]);

  const loadBusinesses = async () => {
    if (!user) return;
    try {
      const userBusinesses = await getBusinessesByOwner(user.id);
      setBusinesses(userBusinesses);
    } catch (error) {
      console.error('Error loading businesses:', error);
    }
    setLoading(false);
  };

  if (!user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <p className="text-center text-gray-600">Please sign in to manage your businesses.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Business Dashboard</h1>
        <div className="space-x-4">
          <button
            onClick={() => setIsCreateEventOpen(true)}
            className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC]"
          >
            Create Paid Event
          </button>
          <button
            onClick={() => setIsCreateBusinessOpen(true)}
            className="bg-[#0066FF] text-white px-4 py-2 rounded-md hover:bg-[#0052CC]"
          >
            Add Business
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0066FF]"></div>
        </div>
      ) : (
        <BusinessList businesses={businesses} onUpdate={loadBusinesses} />
      )}

      <CreateBusinessModal
        isOpen={isCreateBusinessOpen}
        onClose={() => setIsCreateBusinessOpen(false)}
        onBusinessCreated={loadBusinesses}
      />

      <CreateEventModal
        isOpen={isCreateEventOpen}
        onClose={() => setIsCreateEventOpen(false)}
        businesses={businesses}
      />
    </div>
  );
}

export default BusinessPage;