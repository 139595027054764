import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Activity } from '../types/core';

export const searchActivities = async (
  searchTerm: string = '', 
  category: string = 'all'
): Promise<Activity[]> => {
  try {
    const activitiesRef = collection(db, 'activities');
    const q = category !== 'all'
      ? query(activitiesRef, where('category', '==', category))
      : query(activitiesRef);
    
    const snapshot = await getDocs(q);
    const activities = snapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data() 
    } as Activity));

    if (!searchTerm) return activities;

    return activities.filter(activity => 
      activity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } catch (error: any) {
    throw new Error(error.message || 'Failed to search activities');
  }
};

export const getActivity = async (id: string): Promise<Activity> => {
  try {
    const docRef = doc(db, 'activities', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Activity not found');
    }
    
    return { id: docSnap.id, ...docSnap.data() } as Activity;
  } catch (error: any) {
    throw new Error(error.message || 'Failed to fetch activity');
  }
};